import { useQuery } from '@tanstack/react-query';

import lotrClient from '../../../../../../lotrClient';
import { MovieType, QuoteType } from '../../../../../../types';

interface UseCharacterDataProps {
    id: string | undefined;
}

const useCharacterData = ({ id }: UseCharacterDataProps) => {
    const {
        data: movie,
        isError: movieError,
        isLoading: movieIsLoading,
    } = useQuery<MovieType>({
        queryKey: ['movies', `id-${id}`],
        queryFn: () =>
            lotrClient.getMovie({
                id: id!,
            }),
        retry: 1,
    });

    const {
        data: characterNameMap,
        isError: characterNameMapError,
        isLoading: characterNameMapIsLoading,
    } = useQuery<Record<string, string>>({
        queryKey: ['characterNameMap'],
        queryFn: () => lotrClient.getCharacterNameMap(),
        retry: 1,
    });

    const {
        data: quotes,
        isError: quotesError,
        isLoading: quotesAreLoading,
    } = useQuery<QuoteType[]>({
        queryKey: ['movies', 'quotes', `id-${id}`],
        queryFn: () =>
            lotrClient.getMovieQuotes({
                id: id!,
            }),
        retry: 1,
    });

    return {
        characterNameMap,
        characterNameMapError,
        isLoading: movieIsLoading || characterNameMapIsLoading || quotesAreLoading,
        movie,
        movieError,
        quotes,
        quotesError,
    };
};

export default useCharacterData;
