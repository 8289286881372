import styled from '@emotion/styled';

interface LogoContainerProps {
    size?: 'small' | 'large';
}

export const LogoContainer = styled.div<LogoContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;

    ${({ size }) =>
        size === 'small' &&
        `
		img {
			height: 100%;
		}
	`}

    @media screen and (max-width: 899px) {
        img {
            height: auto;
            width: calc(100% - 2rem);
            margin: 0 1rem;
        }
    }
`;
