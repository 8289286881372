import request from '../request';

interface GetMovieArgs {
    id: string;
}

const getMovie = ({ id }: GetMovieArgs) =>
    request
        .get(`/movie/${id}`)
        .then((res) => res.json())
        .then((res) => res.docs[0]);

export default getMovie;
