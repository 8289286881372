import React from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import Movies from '.';
import MovieDetail from './components/MovieDetail';

const routes: RouteObject[] = [
    {
        path: '',
        element: <Movies />,
    },
    {
        path: ':movieId',
        element: <MovieDetail />,
    },
    {
        path: '*',
        loader: () => redirect('/movies'),
    },
];

export default routes;
