import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { Dots, StyledCard } from './CharacterDetail.styles';
import CharacterQuotes from './components/CharacterQuotes';
import Layout from '../../../Layout';
import { CharacterType } from '../../../../types';
import useCharacterData from './hooks/useCharacterData';

const getRaceAndGender = (data: any) => {
    const parts = [];

    if (data?.gender && data?.gender !== 'NaN') {
        parts.push(data.gender);
    }

    if (data?.race && data?.race !== 'NaN') {
        parts.push(data.race);
    }

    return parts.join(' ');
};

const renderDetails = ({ data, small }: { data: CharacterType | undefined; small: boolean }) => {
    const details: { key: string; value: string }[] = [];

    if (data?.birth && data?.birth !== 'NaN') {
        details.push({ key: 'Born', value: data.birth });
    }

    if (data?.death && data?.death !== 'NaN') {
        details.push({ key: 'Died', value: data.death });
    }

    if (data?.realm && data?.realm !== 'NaN') {
        details.push({ key: 'Realm', value: data.realm });
    }

    if (data?.spouse && data?.spouse !== 'NaN') {
        details.push({ key: 'Spouse', value: data.spouse });
    }

    if (data?.height && data?.height !== 'NaN') {
        details.push({ key: 'Height', value: data.height });
    }

    if (data?.hair && data?.hair !== 'NaN') {
        details.push({ key: 'Hair', value: data.hair });
    }

    return (
        <List>
            {details.map((detail) => (
                <ListItem
                    key={detail.key}
                    sx={{
                        display: 'flex',
                        flexDirection: small ? 'row' : 'column',
                        ...(!small && { alignItems: 'flex-start' }),
                    }}
                >
                    <strong>{detail.key}</strong>
                    {small && <Dots />}
                    <span>{detail.value}</span>
                </ListItem>
            ))}
        </List>
    );
};

const CharacterDetail = () => {
    const { characterId: id } = useParams();
    const theme = useTheme();
    const navigate = useNavigate();
    const small = useMediaQuery(theme.breakpoints.up('sm'));

    const { character, characterError, isLoading } = useCharacterData({ id });

    useEffect(() => {
        if (characterError) {
            navigate('/characters');
        }
    }, [characterError, navigate]);

    return (
        <Layout isLoading={isLoading || characterError}>
            <Box display='flex' justifyContent='center'>
                <StyledCard>
                    <CardHeader title={character?.name} subheader={getRaceAndGender(character)} />

                    <CardContent sx={{ paddingTop: '0' }}>{renderDetails({ data: character, small })}</CardContent>

                    {character && character.wikiUrl && (
                        <CardActions>
                            <Button component={RouterLink} to={character.wikiUrl} size='small' target='_blank'>
                                Learn More
                            </Button>
                        </CardActions>
                    )}
                </StyledCard>
            </Box>

            <CharacterQuotes characterId={id} />
        </Layout>
    );
};

export default CharacterDetail;
