import { useQuery } from '@tanstack/react-query';

import lotrClient from '../../../../../../lotrClient';
import { CharacterType, MovieType, QuoteType } from '../../../../../../types';

interface UseCharacterDataProps {
    id: string | undefined;
}

const useCharacterData = ({ id }: UseCharacterDataProps) => {
    const {
        data: character,
        isError: characterError,
        isLoading: characterIsLoading,
    } = useQuery<CharacterType>({
        queryKey: ['characters', `id-${id}`],
        queryFn: () =>
            lotrClient.getCharacter({
                id: id!,
            }),
        retry: 1,
    });

    const {
        data: movies,
        isError: moviesError,
        isLoading: moviesAreLoading,
    } = useQuery<MovieType[]>({
        queryKey: ['movies'],
        queryFn: () => lotrClient.getMovies(),
        retry: 1,
    });

    const {
        data: quotes,
        isError: quotesError,
        isLoading: quotesAreLoading,
    } = useQuery<QuoteType[]>({
        queryKey: ['characters', 'quotes', `id-${id}`],
        queryFn: () =>
            lotrClient.getCharacterQuotes({
                id: id!,
            }),
        retry: 1,
    });

    return {
        character,
        characterError,
        isLoading: characterIsLoading || moviesAreLoading || quotesAreLoading,
        movies,
        moviesError,
        quotes,
        quotesError,
    };
};

export default useCharacterData;
