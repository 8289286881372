import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Button, ListItem } from '@mui/material';

const Navigation = () => {
    const theme = useTheme();
    const belowSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const navLinks = [
        {
            text: 'Characters',
            path: '/characters',
        },
        {
            text: 'Books',
            path: '/books',
        },
        {
            text: 'Movies',
            path: '/movies',
        },
    ];

    return (
        <Container component='nav'>
            <Box
                component='ul'
                display='flex'
                flexDirection={belowSmall ? 'column' : 'row'}
                alignItems='center'
                justifyContent='center'
                marginTop='0'
                padding='0'
                sx={{ listStyle: 'none' }}
            >
                {navLinks.map(({ text, path }) => (
                    <ListItem key={text} component='li' sx={{ width: 'auto', padding: belowSmall ? '0' : '0 1rem' }}>
                        <Button component={Link} to={path} color='secondary'>
                            {text}
                        </Button>
                    </ListItem>
                ))}
            </Box>
        </Container>
    );
};

export default Navigation;
