import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import useCharacterData from '../../hooks/useCharacterData';
import { Quote } from './CharacterQuotes.styles';
import { MovieType } from '../../../../../../types';

interface CharacterQuotesProps {
    characterId: string | undefined;
}

const CharacterQuotes = ({ characterId: id }: CharacterQuotesProps) => {
    const { quotesError, quotes, movies, moviesError } = useCharacterData({ id });

    const movieMap: Record<string, MovieType> = useMemo(() => {
        return (movies || []).reduce((acc, { _id, ...movie }) => {
            return {
                ...acc,
                [_id]: {
                    _id,
                    ...movie,
                },
            };
        }, {} as Record<string, MovieType>);
    }, [movies]);

    if (quotesError || !quotes || quotes.length === 0) {
        return (
            <Typography variant='body1' align='center' color='dimgray'>
                No quotes found
            </Typography>
        );
    }

    return (
        <Box margin='1rem 0'>
            <Typography variant='h6' align='center' gutterBottom>
                Quotes
            </Typography>

            <Box display='flex' alignItems='center' flexDirection='column'>
                {quotes.map(({ _id, dialog, movie }) => (
                    <Quote key={_id}>
                        <Typography variant='body1' fontStyle='italic' gutterBottom>
                            &ldquo;<span>{dialog}</span>&rdquo;
                        </Typography>

                        {!moviesError && (
                            <Link component={RouterLink} to={`/movies/${movie}`} textAlign='right' marginTop='0.5rem'>
                                {movieMap?.[movie]?.name}
                            </Link>
                        )}
                    </Quote>
                ))}
            </Box>
        </Box>
    );
};

export default CharacterQuotes;
