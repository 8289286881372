import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Logo from '../Logo';
import Navigation from '../Navigation';

// import { LoadingContainer } from './Layout.styles';

interface LayoutProps {
    children?: ReactNode | ReactNode[];
    isLoading?: boolean;
    title?: string;
}

const Layout = ({ children, isLoading, title }: LayoutProps) => {
    return (
        <Container>
            <Link title='Home' to='/'>
                <Logo size='small' />
            </Link>

            <Navigation />

            {title && !isLoading && (
                <Typography variant='h4' gutterBottom>
                    {title}
                </Typography>
            )}

            {isLoading ? (
                <Box display='flex' justifyContent='center' marginTop='2rem'>
                    <CircularProgress />
                </Box>
            ) : (
                children
            )}
        </Container>
    );
};

export default Layout;
