import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useQuery } from '@tanstack/react-query';

import Layout from '../Layout';
import lotrClient from '../../lotrClient';
import { BookType } from '../../types';

const Books = () => {
    const { data: books, isLoading } = useQuery<BookType[]>({
        queryKey: ['books'],
        queryFn: () => lotrClient.getBooks(),
    });

    return (
        <Layout title='Books' isLoading={isLoading}>
            <List>
                {books?.length &&
                    books?.map(({ _id, name }) => (
                        <ListItem key={_id}>
                            <Link component={RouterLink} to={`/books/${_id}`}>
                                {name}
                            </Link>
                        </ListItem>
                    ))}
            </List>
        </Layout>
    );
};

export default Books;
