import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useQuery } from '@tanstack/react-query';

import Layout from '../../../Layout';
import lotrClient from '../../../../lotrClient';
import { BookChapterType, BookType } from '../../../../types';

const BookChapters = () => {
    const { bookId: id } = useParams();

    const { data: books, isLoading: isLoadingBooks } = useQuery<BookType[]>({
        queryKey: ['books'],
        queryFn: () => lotrClient.getBooks(),
    });

    const { data: chapters, isLoading: isLoadingChapters } = useQuery<BookChapterType[]>({
        queryKey: ['books', 'chapters'],
        queryFn: () => lotrClient.getBookChapters({ id: id! }),
    });

    const book = books?.find((b) => b._id === id);

    return (
        <Layout title={book?.name} isLoading={isLoadingBooks || isLoadingChapters}>
            <Typography variant='h6'>Chapters</Typography>
            <List>
                {chapters?.length &&
                    chapters?.map(({ _id, chapterName }) => (
                        <ListItem>
                            <Typography variant='body1'>{chapterName}</Typography>
                        </ListItem>
                    ))}
            </List>
        </Layout>
    );
};

export default BookChapters;
