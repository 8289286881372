import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';

export const Quote = styled(Paper)`
    display: flex;
    flex-direction: column;
    margin: 0 1rem 1rem;
    width: calc(600px - 2rem);
    padding: 1rem;

    @media (max-width: 599px) {
        width: calc(100% - 2rem);
    }
`;
