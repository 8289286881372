import { createTheme } from '@mui/material';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#9575cd',
        },
        secondary: {
            main: '#ffe082',
        },
    },
});

export default theme;
