import request from '../request';

interface GetBookChaptersArgs {
    id: string;
}

const getBookChapters = ({ id }: GetBookChaptersArgs) =>
    request
        .get(`/book/${id}/chapter`)
        .then((res) => res.json())
        .then((res) => res.docs);

export default getBookChapters;
