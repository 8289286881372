import { RouteObject, redirect } from 'react-router-dom';

import bookRoutes from '../components/Books/routes';
import characterRoutes from '../components/Characters/routes';
import movieRoutes from '../components/Movies/routes';
import Home from '../components/Home';

const routes: RouteObject[] = [
    {
        path: '/',
        element: <Home />,
    },
    {
        path: '/books',
        children: bookRoutes,
    },
    {
        path: '/characters',
        children: characterRoutes,
    },
    {
        path: '/movies',
        children: movieRoutes,
    },
    {
        path: '*',
        loader: () => redirect('/'),
    },
];

export default routes;
