import request from '../request';

interface GetCharacterArgs {
    id: string;
}

const getCharacter = ({ id }: GetCharacterArgs) =>
    request
        .get(`/character/${id}`)
        .then((res) => res.json())
        .then((res) => res.docs[0]);

export default getCharacter;
