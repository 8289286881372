import { CharacterType } from '../../types';
import request from '../request';

const getCharacterNameMap = () =>
    request
        .get(`/character`)
        .then((res) => res.json())
        .then((res) =>
            res?.docs?.reduce((acc: Record<string, string>, { _id, name }: CharacterType) => {
                return {
                    ...acc,
                    [_id]: name,
                };
            }, {})
        );

export default getCharacterNameMap;
