import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import useMovieData from '../../hooks/useMovieData';
import { Quote } from './MovieQuotes.styles';

interface MovieQuotesProps {
    movieId: string | undefined;
}

const MovieQuotes = ({ movieId: id }: MovieQuotesProps) => {
    const { characterNameMap, characterNameMapError, quotesError, quotes } = useMovieData({ id });

    if (quotesError || !quotes || quotes.length === 0) {
        return (
            <Typography variant='body1' align='center' color='dimgray'>
                No quotes found
            </Typography>
        );
    }

    console.log({ id, characterNameMap });

    return (
        <Box margin='1rem 0'>
            <Typography variant='h6' align='center' gutterBottom>
                Quotes
            </Typography>

            <Box display='flex' alignItems='center' flexDirection='column'>
                {quotes.map(({ _id, dialog, character }) => (
                    <Quote key={_id}>
                        <Typography variant='body1' fontStyle='italic' gutterBottom>
                            &ldquo;<span>{dialog}</span>&rdquo;
                        </Typography>

                        {!characterNameMapError && (
                            <Link component={RouterLink} to={`/characters/${character}`} textAlign='right' marginTop='0.5rem'>
                                {characterNameMap?.[character]}
                            </Link>
                        )}
                    </Quote>
                ))}
            </Box>
        </Box>
    );
};

export default MovieQuotes;
