import { RouteObject, redirect } from 'react-router-dom';

import Books from '.';
import BookChapters from './components/BookChapters';

const routes: RouteObject[] = [
    {
        path: '',
        element: <Books />,
    },
    {
        path: ':bookId',
        element: <BookChapters />,
    },
    {
        path: '*',
        loader: () => redirect('/books'),
    },
];

export default routes;
