import styled from '@emotion/styled';
import Card from '@mui/material/Card';

export const Dots = styled.div`
    background-image: linear-gradient(to right, ${({ theme }) => (theme as any).palette.text.disabled} 10%, rgba(255, 255, 255, 0) 0%);
    background-position: left bottom;
    background-size: 10px 1px;
    background-repeat: repeat-x;
    flex-grow: 1;
    margin: 0 0.5rem;
    height: 0.8rem;
`;

export const StyledCard = styled(Card)`
    margin: 0 1rem 1rem;

    @media (min-width: 600px) {
        min-width: calc(600px - 2rem);
    }

    @media (max-width: 599px) {
        width: calc(100% - 2rem);
    }
`;
