import React, { ReactComponentElement } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import { StyledLink } from './SectionCard.styles';

interface SectionCardProps {
    description: string;
    href: string;
    icon: ReactComponentElement<any>;
    title: string;
}

const SectionCard = ({ description, href, icon, title }: SectionCardProps) => (
    <StyledLink title={title} to={href}>
        <Card sx={{ height: '100%' }}>
            <CardActionArea sx={{ height: '100%' }}>
                <CardContent>
                    <Box display='flex' alignItems='center' marginBottom='0.5rem'>
                        <Box component='span' marginRight='1rem'>
                            {icon}
                        </Box>

                        <Typography variant='h5'>{title}</Typography>
                    </Box>

                    <Typography variant='body2'>{description}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </StyledLink>
);

export default SectionCard;
