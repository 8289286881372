import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const CardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    max-width: 1200px;
    margin: 0 1rem;

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 479px) {
        grid-template-columns: 1fr;
    }
`;
