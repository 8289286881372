import { persistQueryClient } from '@tanstack/query-persist-client-core';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { STALE_TIME } from '../config';

// Persist query cache to localStorage
const persister = createSyncStoragePersister({ storage: window.localStorage });

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: STALE_TIME,
        },
    },
});

persistQueryClient({
    queryClient,
    persister,
});

export default queryClient;
