import request from '../request';

interface GetCharacterQuotesArgs {
    id: string;
}

const getCharacterQuotes = ({ id }: GetCharacterQuotesArgs) =>
    request
        .get(`/character/${id}/quote`)
        .then((res) => res.json())
        .then((res) => res.docs);

export default getCharacterQuotes;
