import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useQuery } from '@tanstack/react-query';

import lotrClient from '../../lotrClient';
import { MovieType } from '../../types';
import Layout from '../Layout';

const Movies = () => {
    const { data: movies, isLoading } = useQuery<MovieType[]>({
        queryKey: ['movies'],
        queryFn: () => lotrClient.getMovies(),
    });

    return (
        <Layout title='Movies' isLoading={isLoading}>
            <List>
                {movies?.length &&
                    movies?.map(({ _id, name }) => (
                        <ListItem key={_id}>
                            <Link component={RouterLink} to={`/movies/${_id}`}>
                                {name}
                            </Link>
                        </ListItem>
                    ))}
            </List>
        </Layout>
    );
};

export default Movies;
