import getBookChapters from './getBookChapters';
import getBooks from './getBooks';
import getCharacter from './getCharacter';
import getCharacterNameMap from './getCharacterNameMap';
import getCharacterQuotes from './getCharacterQuotes';
import getCharacters from './getCharacters';
import getMovie from './getMovie';
import getMovieQuotes from './getMovieQuotes';
import getMovies from './getMovies';

const client = {
    getBookChapters,
    getBooks,
    getCharacter,
    getCharacterNameMap,
    getCharacterQuotes,
    getCharacters,
    getMovie,
    getMovieQuotes,
    getMovies,
};

export default client;
