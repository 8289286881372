import { RouteObject, redirect } from 'react-router-dom';

import Characters from '.';
import CharacterDetail from './components/CharacterDetail';

const routes: RouteObject[] = [
    {
        path: '',
        element: <Characters />,
    },
    {
        path: ':characterId',
        element: <CharacterDetail />,
    },
    {
        path: '*',
        loader: () => redirect('/characters'),
    },
];

export default routes;
