import { GridSortDirection } from '@mui/x-data-grid';

import { CharacterSortFields } from '../../types';
import request from '../request';

interface GetCharactersArgs {
    limit?: number;
    page?: number;
    sortDirection?: GridSortDirection;
    sortField?: CharacterSortFields;
}

const getCharacters = ({ limit, page, sortDirection, sortField }: GetCharactersArgs = {}) => {
    limit = limit || 50;
    page = page || 1;
    sortDirection = sortDirection || 'asc';
    sortField = sortField || 'name';

    return request.get(`/character?limit=${limit}&page=${page}&sort=${sortField}:${sortDirection}`).then((res) => res.json());
};

export default getCharacters;
