import React, { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel, GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { useQuery } from '@tanstack/react-query';

import Layout from '../Layout';
import lotrClient from '../../lotrClient';
import { CharacterSortFields, CharacterSortModel } from '../../types';

const coerceBadValues = ({ value }: { value: string }) => {
    if (value === 'NaN' || value === 'undefined' || value === 'null' || value === '') {
        return '-';
    }

    return value;
};

const buildSearchParams = (paginationModel: GridPaginationModel, sortModel: CharacterSortModel[], searchParams: URLSearchParams) => {
    const { page, pageSize } = paginationModel;
    const { field, sort } = sortModel[0];

    const newSearchParams = new URLSearchParams();

    if (page > 0) {
        newSearchParams.set('page', String(page + 1));
    }

    if (pageSize !== 50) {
        newSearchParams.set('pageSize', String(pageSize));
    }

    // Don't show the params for the default sort unless we've already changed it
    if (!(field === 'name' && sort === 'asc') || searchParams.get('sortField')) {
        newSearchParams.set('sortField', field);
        newSearchParams.set('sortDirection', sort!);
    }

    return newSearchParams;
};

const Characters = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));

    // We use 1-based indexing for the URL, but the API uses 0-based indexing
    const page = Number(searchParams.get('page')) - 1 || 0;
    const pageSize = Number(searchParams.get('pageSize')) || 50;
    const sortField = searchParams.get('sortField') || 'name';
    const sortDirection = searchParams.get('sortDirection') || 'asc';

    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: page <= 0 ? 0 : page, pageSize });
    const [sortModel, setSortModel] = useState<CharacterSortModel[]>([
        { field: sortField as CharacterSortFields, sort: sortDirection as GridSortDirection },
    ]);

    const handlePaginationModelChange = useCallback(
        (newPaginationModel: GridPaginationModel) => {
            setPaginationModel(newPaginationModel);

            const newSearchParams = buildSearchParams(newPaginationModel, sortModel, searchParams);

            navigate(`/characters?${newSearchParams.toString()}`);
        },
        [navigate, searchParams, sortModel]
    );

    const handleSortModelChange = useCallback(
        (newSortModel: GridSortModel) => {
            // When sort is removed, the newSortModel is an empty array. Set it back to default sort.
            if (!newSortModel.length) {
                newSortModel.push({ field: 'name', sort: 'asc' });
            }

            setSortModel(newSortModel as CharacterSortModel[]);

            const newSearchParams = buildSearchParams(paginationModel, newSortModel as CharacterSortModel[], searchParams);

            navigate(`/characters?${newSearchParams.toString()}`);
        },
        [navigate, paginationModel, searchParams]
    );

    useEffect(() => {
        if (paginationModel.page && paginationModel.pageSize && (page !== paginationModel.page || pageSize !== paginationModel.pageSize)) {
            setPaginationModel({ page, pageSize });
        }

        if (
            sortModel[0] &&
            sortModel[0].field &&
            sortModel[0].sort &&
            (sortField !== sortModel[0].field || sortDirection !== sortModel[0].sort)
        ) {
            setSortModel([{ field: sortField as CharacterSortFields, sort: sortDirection as GridSortDirection }]);
        }
    }, [page, pageSize, sortField, sortDirection, sortModel, paginationModel]);

    const { data, isLoading } = useQuery({
        queryKey: [
            'characters',
            `sortBy-${sortModel[0].field}:${sortModel[0].sort}`,
            `pageSize-${paginationModel.pageSize}`,
            `page-${paginationModel.page}`,
        ],
        queryFn: () =>
            lotrClient.getCharacters({
                limit: paginationModel.pageSize,
                page: paginationModel.page + 1,
                sortField: sortModel[0].field,
                sortDirection: sortModel[0].sort,
            }),
    });
    const { docs: characters, ...paginationData } = data || { docs: [] };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            type: 'string',
            renderCell: ({ id, value }) => (
                <Link component={RouterLink} to={`/characters/${id}`}>
                    {value}
                </Link>
            ),
            flex: 10,
        },
        {
            field: 'race',
            headerName: 'Race',
            type: 'string',
            valueFormatter: coerceBadValues,
            flex: 3,
            maxWidth: 250,
        },
        {
            field: 'gender',
            headerName: 'Gender',
            type: 'string',
            valueFormatter: coerceBadValues,
            flex: 3,
            maxWidth: 250,
        },
        ...(isMedium
            ? [
                  {
                      field: 'birth',
                      headerName: 'Born',
                      type: 'string',
                      valueFormatter: coerceBadValues,
                      flex: 3,
                      maxWidth: 250,
                  },
                  {
                      field: 'death',
                      headerName: 'Died',
                      type: 'string',
                      valueFormatter: coerceBadValues,
                      flex: 3,
                      maxWidth: 250,
                  },
              ]
            : []),
    ];

    return (
        <Layout title='Characters' isLoading={isLoading}>
            <DataGrid
                density='compact'
                disableColumnMenu
                getRowId={({ _id }) => _id}
                rows={characters}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel,
                    },
                    sorting: {
                        sortModel,
                    },
                }}
                paginationMode='server'
                sortingMode='server'
                onPaginationModelChange={handlePaginationModelChange}
                onSortModelChange={handleSortModelChange}
                rowCount={paginationData.total}
            />
        </Layout>
    );
};

export default Characters;
