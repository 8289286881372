import React from 'react';

import { LogoContainer } from './Logo.styles';

interface LogoProps {
    size?: 'small' | 'large';
}

const Logo = ({ size = 'large' }: LogoProps) => (
    <LogoContainer size={size}>
        <img src='/images/lotr-logo.png' alt='logo' />
    </LogoContainer>
);

export default Logo;
