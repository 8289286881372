import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { Dots, StyledCard } from './MovieDetail.styles';
import MovieQuotes from './components/MovieQuotes';
import Layout from '../../../Layout';
import { MovieType } from '../../../../types';
import useMovieData from './hooks/useMovieData';

const renderDetails = ({ data, small }: { data: MovieType | undefined; small: boolean }) => {
    const details: { key: string; value: string }[] = [];

    details.push({ key: 'Runtime', value: `${data?.runtimeInMinutes} min` });
    details.push({ key: 'Budget', value: `$${data?.budgetInMillions} mil` });
    details.push({ key: 'Revenue', value: `$${data?.boxOfficeRevenueInMillions} mil` });
    details.push({ key: 'Academy Awards Nominations', value: data?.academyAwardNominations?.toString()! });
    details.push({ key: 'Academy Awards Wins', value: data?.academyAwardWins?.toString()! });
    details.push({ key: 'Rotten Tomatoes Score', value: `${data?.rottenTomatoesScore}%` });

    return (
        <List>
            {details.map((detail) => (
                <ListItem
                    key={detail.key}
                    sx={{
                        display: 'flex',
                        flexDirection: small ? 'row' : 'column',
                        ...(!small && { alignItems: 'flex-start' }),
                    }}
                >
                    <strong>{detail.key}</strong>
                    {small && <Dots />}
                    <span>{detail.value}</span>
                </ListItem>
            ))}
        </List>
    );
};

const MovieDetail = () => {
    const { movieId: id } = useParams();
    const theme = useTheme();
    const navigate = useNavigate();
    const small = useMediaQuery(theme.breakpoints.up('sm'));

    const { movie, movieError, isLoading } = useMovieData({ id });

    useEffect(() => {
        if (movieError) {
            navigate('/movies');
        }
    }, [movieError, navigate]);

    return (
        <Layout isLoading={isLoading || movieError}>
            <Box display='flex' justifyContent='center'>
                <StyledCard>
                    <CardHeader title={movie?.name} />

                    <CardContent sx={{ paddingTop: '0' }}>{renderDetails({ data: movie, small })}</CardContent>
                </StyledCard>
            </Box>

            <MovieQuotes movieId={id} />
        </Layout>
    );
};

export default MovieDetail;
