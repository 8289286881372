import request from '../request';

interface GetMovieQuotesArgs {
    id: string;
}

const getMovieQuotes = ({ id }: GetMovieQuotesArgs) =>
    request
        .get(`/movie/${id}/quote`)
        .then((res) => res.json())
        .then((res) => res.docs);

export default getMovieQuotes;
