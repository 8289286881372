import React from 'react';
import GroupsIcon from '@mui/icons-material/Groups';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MovieIcon from '@mui/icons-material/Movie';
import Container from '@mui/material/Container';

import { CardContainer } from './Home.styles';
import SectionCard from './components/SectionCard';
import Logo from '../Logo';

const Header = () => {
    return (
        <Container>
            <Logo />

            <CardContainer>
                <SectionCard
                    title='Characters'
                    description='Get information about your favorite LOTR characters'
                    icon={<GroupsIcon fontSize='large' />}
                    href='/characters'
                />

                <SectionCard
                    title='Books'
                    description='Get information about the LOTR book series'
                    icon={<MenuBookIcon fontSize='large' />}
                    href='/books'
                />

                <SectionCard
                    title='Movies'
                    description='Get information about the various LOTR movies'
                    icon={<MovieIcon fontSize='large' />}
                    href='/movies'
                />
            </CardContainer>
        </Container>
    );
};

export default Header;
