import { API_ACCESS_TOKEN, API_BASE_URL } from '../../config';

export const get = async (url: string) =>
    fetch(`${API_BASE_URL}${url}`, {
        headers: {
            Authorization: `Bearer ${API_ACCESS_TOKEN}`,
        },
    });

const request = {
    get,
};

export default request;
